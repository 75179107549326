













import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import md5 from 'md5'
import GlobalSearch from '@/components/GlobalSearch.vue'
import AdminNavbar from '@/components/AdminNavbar.vue'
import gql from 'graphql-tag'
import { Environment } from '@/models'
import { ApolloQueryResult } from 'apollo-client'
import DashboardAppBar from '@/components/DashboardAppBar.vue'

@Component({
  components: {
    GlobalSearch,
    AdminNavbar,
    DashboardAppBar
  },
  apollo: {
    environment: {
      query: gql`
        query ($environmentId: ID) {
          environment(environmentId: $environmentId) {
            _id
            name
            url
            accentColor
            useCustomCss
            customCss
            logo {
              url
            }
            loginLogo {
              url
            }
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      result(r: ApolloQueryResult<{ environment: Environment }>, key: string) {
        this.$store.commit('app/addRecent', r.data.environment)
      },
      skip() {
        return !this.environmentId
      }
    }
  }
})
export default class Admin extends Vue {
  globalSearchOpen = false
  environment: Environment | null = null

  @Prop({ type: String }) environmentId?: string

  @Watch('environment')
  async setEnvironmentVariables(env: Environment) {
    const style = document.getElementById('customCSS') as HTMLStyleElement
    if (!env) return
    this.$vuetify.theme.themes.light.primary =
      this.$vuetify.theme.themes.light.secondary =
      this.$vuetify.theme.themes.light.accent =
        env.accentColor || '#808080'
    this.$vuetify.theme.themes.dark.primary =
      this.$vuetify.theme.themes.dark.secondary =
      this.$vuetify.theme.themes.dark.accent =
        env.accentColor || '#808080'
    if (env.useCustomCss && env.customCss) {
      style.innerText = env.customCss
    } else {
      style.innerText = ''
    }
  }
}
